// @ts-nocheck
import React, { useEffect, useMemo, useState } from "react";
// import Storage from '@aws-amplify/storage';
import { useSelector } from "react-redux";
import MapViewerReact from "./MapViewerReact";
// import GraphPlaceholder from "./GraphPlaceholder";
// import FillPageHelper from "./FillPageHelper";
import PrecipPercentile from "./forecastGraphs/PrecipPercentile";
import { calculatePercentile } from "../util/utils";
import { useDispatch } from "react-redux";
// import { setFlyLocation } from "../store/mapStateSlice";
import { ReactComponent as CloudRaining } from "../assets/icons/cloud-raining-05.svg";
import { ReactComponent as WindIcon } from "../assets/icons/wind-02.svg";
import { ReactComponent as DownloadIcon } from "../assets/icons/download-02.svg";
import { ReactComponent as SunIcon } from "../assets/icons/sun.svg";
import { ReactComponent as Table } from "../assets/icons/layout-grid-02.svg";
import { ReactComponent as HurricaneIcon } from "../assets/icons/hurricane-02.svg";
import { ReactComponent as Graph } from "../assets/icons/line-chart.svg";
import { ReactComponent as Hybrid } from "../assets/icons/rows-02.svg";
// import { ReactComponent as Hybrid } from "../assets/icons/layers-two-02.svg";
import { ReactComponent as DownIcon } from "../assets/icons/chevron-down.svg";
import { useParams } from "react-router-dom";
import tzlookup from "tz-lookup";
import {
  getTHEFW,
  getOHHFC,
  interpolateCurveValues,
  fetchSolarEnergyForecast,
  getTHEFWwind80,
  interpolateCurveValuesNoDates,
  interpolateValues,
} from "../api/awsLamba";
import ScatterGraph from "./forecastGraphs/ScatterGraph";
import LoadingIcon from "./LoadingIcon";
// import SolarPercentileGraph from "./forecastGraphs/SolarPercentileGraph";
import TabularData from "./forecastGraphs/TabularData";
import ReactGA from "react-ga4";
import { record } from "aws-amplify/analytics";
import ForecastSelect from "./ForecastSelect";
import TabViewSelect from "./TabViewSelect";
import { list, downloadData } from "@aws-amplify/storage";
import ProcessedScatterGraph from "./forecastGraphs/ProcessedScatterGraph";

// Move this, will also need it for energy model
const viewSchema = {
  hybrid: {
    name: "Hybrid View",
    Icon: Hybrid,
  },
  graph: {
    name: "Wind (10m)",
    Icon: Graph,
  },
  table: {
    name: "Table View",
    Icon: Table,
  },
};

const AssetGraph = ({ selectedAsset }) => {
  const { assetid, groupid } = useParams();
  const { forecast, alerts } = useSelector(({ forecast, alerts }) => ({
    forecast,
    alerts,
  }));
  const dispatch = useDispatch();
  const [alertCatalogue, setAlertCatalogue] = useState(null);
  const [activeForecast, setActiveForecast] = useState("Wind80");
  const [forecastVariation, setForecastVariation] = useState("Speed");
  const [wind10, setWind10] = useState(null);
  const [wind80, setWind80] = useState(null);
  const [precipitation, setPrecipitation] = useState(null);
  const [solarRadiation, setSolarRadiation] = useState(null);
  const [windPower80Data, setWindPower80Data] = useState(null);
  const [processedModelData, setProcessedModelData] = useState(null);

  // High Res comes from same source, refactor?
  const [highResPrecip, setHighResPrecip] = useState(null);
  const [highResSolar, setHighResSolar] = useState(null);
  const [highResWind10, setHighResWind10] = useState(null);
  const [highResWind80, setHighResWind80] = useState(null);
  const [highResWindPower80Data, setHighResWindPower80Data] = useState(null);
  const [highResTemp, setHighResTemp] = useState(null);

  const [dateTimeCycle, setDateTimeCycle] = useState(null);
  const [timeZone, setTimeZone] = useState("UTC");
  const [displayView, setDisplayView] = useState("hybrid");
  const [solarEnergyInputs, setSolarEnergyInputs] = useState({
    thefw: null,
    ohhfc: null,
  });
  const [solarPowerData, setSolarPowerData] = useState(null);

  const [interpolateChecked, setInterpolateChecked] = useState(true);

  // CRAIG sort of reuse this...
  // const getSManifest = async () => {
  //   const file = await Storage.get("***/manifest.json", {
  //     download: true,
  //     cacheControl: "no-cache",
  //   });
  //   const manifestJSON = await new Response(file.Body).json();
  //   setManifest(manifestJSON);
  // };

  const getShortForecast = async () => {
    const res = await getOHHFC(selectedAsset.lat, selectedAsset.lng);
    const { precip, solar, wind, temp, solarEnergyWeatherDataInputs } = res;
    setHighResPrecip(precip);
    setHighResSolar(solar);
    setHighResWind10(wind.wind10);
    setHighResWind80(wind.wind80);
    setHighResTemp(temp);
    setSolarEnergyInputs((state) => ({
      ...state,
      ohhfc: solarEnergyWeatherDataInputs,
    }));
  };

  // 0.5 resolution
  const getWind80 = async () => {
    const wind = await getTHEFWwind80(selectedAsset.lat, selectedAsset.lng);
    setWind80(wind);
  };

  // 0.25 resolution
  const getForecasts = async () => {
    const res = await getTHEFW(selectedAsset.lat, selectedAsset.lng);
    const { precip, solar, wind, solarEnergyWeatherDataInputs, dateTimef } =
      res;

    setPrecipitation(precip);
    setWind10(wind);
    setSolarRadiation(solar);
    setSolarEnergyInputs((state) => ({
      ...state,
      thefw: solarEnergyWeatherDataInputs,
    }));
    setDateTimeCycle(res.dateTime);
  };

  useEffect(() => {
    if (forecastVariation === "Power") {
      setForecastandVariation(activeForecast);
    }

    if (selectedAsset?.processedModels?.items.length > 0) {
      getProcessedModelS3(selectedAsset?.processedModels?.items);
    } else if (activeForecast.includes("processed-")) {
      setActiveForecast("Wind80");
      setForecastVariation("Speed");
      setProcessedModelData(null);
    } else {
      setProcessedModelData(null);
    }

    setPrecipitation(null);
    setWind10(null);
    setWind80(null);
    setWindPower80Data(null);
    setSolarRadiation(null);
    setDateTimeCycle(null);

    getForecasts();
    getShortForecast();
    getWind80();
    setSolarEnergyInputs({ thefw: null, ohhfc: null });
    setSolarPowerData(null);

    setHighResPrecip(null);
    setHighResSolar(null);
    setHighResWind10(null);
    setHighResWind80(null);
    setHighResTemp(null);
  }, [selectedAsset]);

  const callSolarEnergyProductionAsync = async (datta) => {
    const res = await fetchSolarEnergyForecast(datta);
    setSolarPowerData(res);
  };

  const getProcessedModelS3 = async (items) => {
    const processedModelFolder = items[0].routeLong.replace(
      `/${items[0].routeShort}/`,
      ""
    );
    const processedModelRouteDir = `${processedModelFolder}/directory.json`;
    const downloadResult = await downloadData({
      cacheControl: "no-cache",
      path: processedModelRouteDir,
    }).result;
    const jsonData = await downloadResult.body.text();
    const recentProcessedModelDirectory = JSON.parse(jsonData);
    const promises = Object.keys(recentProcessedModelDirectory).map(
      async (key) => {
        const downloadProcessedModel = await downloadData({
          path: `${processedModelFolder}/${key}/${recentProcessedModelDirectory[key]}`, //"private/us-east-1:63445abc-adce-cfa6-1f1a-3492e231a01d/processedModels/bd98e9a8-b7e1-4221-a27e-36b7e28d5337/directory.json",
        }).result;
        const jsonData = await downloadProcessedModel.body.text();
        const processedModelResult = JSON.parse(jsonData);
        return { key, processedModelResult };
      }
    );

    const results = await Promise.all(promises);

    const finalprocessedModelData = results.reduce(
      (acc, { key, processedModelResult }) => {
        acc[key] = processedModelResult;
        return acc;
      },
      {}
    );

    // handle if another active Forecast and processed Model exists in selected asset
    if (activeForecast.includes("processed-")) {
      const pModelParameter = activeForecast.split("-")[1];
      if (
        selectedAsset?.processedModels?.items?.some(
          (pmodel) => pmodel.routeShort === pModelParameter
        )
      ) {
        const variations =
          finalprocessedModelData?.[pModelParameter]?.models ?? {};
        const variationKeys = Object.keys(variations);
        const variationIndex = variationKeys.indexOf(forecastVariation);
        if (variationIndex === -1) {
          setForecastVariation(variationKeys[0]);
        } else {
          setForecastVariation(variationKeys[variationIndex]);
        }
      } else {
        // processed, but doesn't exist in that asset
        setActiveForecast("Wind80");
        setForecastVariation("Speed");
      }
    }

    setProcessedModelData(finalprocessedModelData);
  };

  useEffect(() => {
    if (
      solarEnergyInputs.ohhfc &&
      solarEnergyInputs.thefw &&
      selectedAsset.solarCurveId
    ) {
      const { solarCurve } = selectedAsset;
      const solarEnergyObj = {
        location: {
          latitude: selectedAsset.lat,
          longitude: selectedAsset.lng,
          name: selectedAsset.name, //Remove this
        },
        panel: {
          elevation: selectedAsset.elevation,
          pdc0: solarCurve.dcCap,
          pac0: solarCurve.acCap,
          gammaPdc: solarCurve.thermalLoss,
          panelEfficiency: solarCurve.panelEfficiency,
          minIrradiance: solarCurve.minIrradiance,
          tracking: solarCurve.trackingType.toLowerCase(),
        },
        trackingParams: {
          panelTilt: solarCurve.arrayTilt,
          panelAzimuth: solarCurve.arrayAzimuth,
          maxTilt: solarCurve.maxTilt,
          groundCoverRatio: solarCurve.groundCoverRatio,
        },
        weatherData: {
          ohhfc: solarEnergyInputs.ohhfc,
          ...solarEnergyInputs.thefw,
        },
      };
      callSolarEnergyProductionAsync(solarEnergyObj);
    }
  }, [solarEnergyInputs.ohhfc, solarEnergyInputs.thefw]);

  // Craig move some of this to utils
  useEffect(() => {
    if (wind80 && selectedAsset?.windCurve?.curveData) {
      const sortedCurveData = [...selectedAsset.windCurve.curveData].sort(
        (a, b) => a.x - b.x
      );
      const percentiles = [10, 30, 50, 70, 90];
      const percentileWindPower = percentiles.reduce(
        (acc, curr) => ({ ...acc, [`p${curr}`]: [] }),
        {}
      );
      const percentileWindPowerInterpolated = JSON.parse(
        JSON.stringify(percentileWindPower)
      );

      const ensembleWind = JSON.parse(JSON.stringify(wind80.isoKeyMagEnsemble));
      const ensembleWindInterpolated = JSON.parse(
        JSON.stringify(wind80.isoKeyMagEnsembleInterpolate)
      );

      const windPowerAverageForecast = [];
      const windPowerAverageForecastInterpolated = [];
      Object.keys(ensembleWind).forEach((isoKey, i) => {
        const ensembles = ensembleWind[isoKey];
        // this pops ctrl, should only have 30 ensembles
        ensembles.pop();
        const ensemblePowerArray = interpolateCurveValuesNoDates(
          sortedCurveData,
          ensembles
        );

        const windPowerAverage =
          ensemblePowerArray.reduce((acc, curr) => acc + curr) /
          ensemblePowerArray.length;
        windPowerAverageForecast.push({
          y: windPowerAverage,
          x: new Date(parseInt(isoKey)),
        });
        percentiles.forEach((pctl) => {
          const pctlEnsemblePower = calculatePercentile(
            ensemblePowerArray,
            pctl
          );
          percentileWindPower[`p${pctl}`].push({
            y: pctlEnsemblePower,
            x: new Date(parseInt(isoKey)),
          });
        });
      });

      Object.keys(ensembleWindInterpolated).forEach((isoKey, i) => {
        const ensembles = ensembleWindInterpolated[isoKey];
        // this pops ctrl, should only have 30 ensembles
        ensembles.pop();
        const ensemblePowerArray = interpolateCurveValuesNoDates(
          sortedCurveData,
          ensembles
        );

        const windPowerAverage =
          ensemblePowerArray.reduce((acc, curr) => acc + curr) /
          ensemblePowerArray.length;
        windPowerAverageForecastInterpolated.push({
          y: windPowerAverage,
          x: new Date(parseInt(isoKey)),
        });
        percentiles.forEach((pctl) => {
          const pctlEnsemblePower = calculatePercentile(
            ensemblePowerArray,
            pctl
          );
          percentileWindPowerInterpolated[`p${pctl}`].push({
            y: pctlEnsemblePower,
            x: new Date(parseInt(isoKey)),
          });
        });
      });

      setWindPower80Data({
        raw: {
          Power: {
            average: windPowerAverageForecast,
            control: interpolateCurveValues(
              sortedCurveData,
              wind80.raw.Speed.control
            ),
            percentiles: percentileWindPower,
          },
        },
        interpolated: {
          Power: {
            average: windPowerAverageForecastInterpolated,
            control: interpolateCurveValues(
              sortedCurveData,
              wind80.interpolated.Speed.control
            ),
            percentiles: percentileWindPowerInterpolated,
          },
        },
      });
    }
  }, [wind80]);

  useEffect(() => {
    if (highResWind80?.Speed && selectedAsset?.windCurve?.curveData) {
      const sortedCurveData = [...selectedAsset.windCurve.curveData].sort(
        (a, b) => a.x - b.x
      );
      const highResWindPower = interpolateCurveValues(
        sortedCurveData,
        highResWind80.Speed
      );
      setHighResWindPower80Data(highResWindPower);
    }
  }, [highResWind80?.Speed]);

  useEffect(() => {
    const handleShortcut = (event) => {
      // Check if Ctrl key is pressed along with the 'K' key
      if (event.ctrlKey && event.key === "k") {
        event.preventDefault(); // Prevent the default action to avoid conflicts with browser shortcuts

        // Use the functional update form to toggle the timeZone state
        setTimeZone((currentTimeZone) => {
          // Determine and return the new state based on the current state
          const local = Intl.DateTimeFormat().resolvedOptions().timeZone;
          const assetTimeZone = tzlookup(selectedAsset.lat, selectedAsset.lng);
          const timeZoneArray = ["UTC", local, assetTimeZone];
          const itemIndex = timeZoneArray.indexOf(currentTimeZone);
          if (itemIndex === 2 || itemIndex === -1) {
            return timeZoneArray[0];
          } else {
            return timeZoneArray[itemIndex + 1];
          }
        });
      }
    };
    // Attach the event listener for keydown when the component mounts
    window.addEventListener("keydown", handleShortcut);

    // Return a function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleShortcut);
    };
  }, [selectedAsset]);

  //Alerts
  // useEffect(() => {
  //   const forecastAlerts = forecast.shortGefsAlerts?.[groupid]?.[assetid] ?? {};

  //   const alertCatalogueTemp = Object.keys(forecastAlerts).reduce(
  //     (acc, alertId) => {
  //       const alertRecord = alerts.groups[groupid].find(
  //         (currAlert) => currAlert.id === alertId
  //       );

  //       if (!alertRecord.active) {
  //         return acc;
  //       }
  //       const alertIndexes = forecastAlerts[alertId].map((alertTimeIndex) => ({
  //         alertTimeIndex,
  //         alertId,
  //       }));

  //       return [...acc, ...alertIndexes];
  //     },
  //     []
  //   );
  //   setAlertCatalogue(alertCatalogueTemp);
  // }, [groupid, assetid, forecast.shortGefsAlerts]);

  const setForecastandVariation = (fc) => {
    if (fc === "Precipitation") {
      setActiveForecast("Precipitation");
      setForecastVariation("Hourly");
    } else if (fc === "Wind10") {
      setActiveForecast("Wind10");
      setForecastVariation("Speed");
    } else if (fc === "Wind80") {
      setActiveForecast("Wind80");
      setForecastVariation("Speed");
    } else if (fc === "Solar") {
      setActiveForecast("Solar");
      setForecastVariation("sWave");
    } else if (fc.includes("processed-")) {
      setActiveForecast(fc);
      // Craig refactor to do this to ALL OF THEM
      setForecastVariation(foreCastSchema[fc].variations[0].key);
    }

    record({
      name: "changeForecastParameter",
      attributes: {
        forecast: fc,
        processed: fc.includes("processed"),
      },
    });

    ReactGA.event({
      category: "Navigation",
      action: "Change Forecast",
      label: fc,
    });
  };

  const handleVariationChange = (fcVariationKey) => {
    setForecastVariation(fcVariationKey);
    record({
      name: "changeForecastVariation",
      attributes: {
        variation: fcVariationKey,
        forecast: activeForecast,
      },
    });

    ReactGA.event({
      category: "Navigation",
      action: "Change Forecast Variation",
      label: `${activeForecast}_${fcVariationKey}`,
    });
  };

  const renderLoadingIcon = () => (
    <div className="flex-1 flex h-full justify-center bg-white border ">
      <LoadingIcon
        borderThick={9}
        logoMult={0.6}
        pixelSize={100}
        fullPage={false}
      />
    </div>
  );

  const renderTable = () => {
    const flatData = getFlatData(activeForecast, forecastVariation);
    if (!flatData) {
      return renderLoadingIcon();
    }

    const yAxisLabels = {
      Precipitation: "Millimeters (mm)",
      Wind10: {
        Speed: "Meter per Second (m/s)",
        Direction: "Degrees",
      },
      Wind80: {
        Speed: "Meter per Second (m/s)",
        Direction: "Degrees",
        Power: "Megawatt (MW)",
      },
      Solar: "Radiation Flux (W/m²)",
    };

    let yaxis;

    if (activeForecast === "Precipitation") {
      yaxis = yAxisLabels.Precipitation;
    } else if (activeForecast === "Wind10" || activeForecast === "Wind80") {
      yaxis = yAxisLabels[activeForecast][forecastVariation];
    } else if (activeForecast === "Solar") {
      yaxis = yAxisLabels.Solar;
    } else if (activeForecast.includes("processed-")) {
      const [_, processedActiveForecast] = activeForecast.split("-");
      yaxis =
        processedModelData?.[processedActiveForecast]?.models[forecastVariation]
          .yUnit;
    }

    return <TabularData data={flatData} yaxis={yaxis} timeZone={timeZone} />;
  };

  const renderGraph = () => {
    if (activeForecast === "Precipitation") {
      if (!precipitation) {
        return renderLoadingIcon();
      }

      let format = interpolateChecked ? "interpolated" : "raw";
      const data = precipitation[format][forecastVariation];

      let highResData = highResPrecip?.Hourly;
      if (forecastVariation === "Cumulative") {
        highResData = highResPrecip?.Cumulative;
      }

      return (
        <PrecipPercentile
          timeZone={timeZone}
          highResData={highResData}
          data={data.percentiles}
          average={data?.average}
          control={data?.control}
          // alerts={alertCatalogue}
          yaxis="Precipitation (mm)"
        />
      );
    }

    if (activeForecast === "Wind10") {
      if (!wind10) {
        return renderLoadingIcon();
      }

      let format = interpolateChecked ? "interpolated" : "raw";
      const data = wind10[format][forecastVariation];

      let yaxis = "Speed (m/s)";
      let highResData = highResWind10?.Speed;
      if (forecastVariation === "Direction") {
        yaxis = "Direction (degrees)";
        highResData = highResWind10?.Direction;
      }

      return (
        <ScatterGraph
          timeZone={timeZone}
          data={data.percentiles}
          highResData={highResData}
          average={data?.average}
          control={data?.control}
          yaxis={yaxis}
        />
      );
    }
    if (activeForecast === "Wind80") {
      if (!wind80) {
        return renderLoadingIcon();
      }

      let format = interpolateChecked ? "interpolated" : "raw";

      const dataSource =
        forecastVariation === "Power" ? windPower80Data : wind80;
      const data = dataSource[format][forecastVariation];

      let yaxis = null;
      let highResData = null;

      switch (forecastVariation) {
        case "Direction":
          yaxis = "Direction (degrees)";
          highResData = highResWind80?.Direction;
          break;
        case "Speed":
          yaxis = "Speed (m/s)";
          highResData = highResWind80?.Speed;
          break;
        case "Power":
          yaxis = "MW";
          highResData = highResWindPower80Data;
          break;
      }

      return (
        <ScatterGraph
          timeZone={timeZone}
          data={data.percentiles}
          highResData={highResData}
          average={data?.average}
          control={data?.control}
          yaxis={yaxis}
        />
      );
    }
    if (activeForecast === "Solar") {
      if (!solarRadiation) {
        return renderLoadingIcon();
      }
      let format = interpolateChecked ? "interpolated" : "raw";
      let yaxis = null;
      let variationEquiv = null;
      let highResData = null;
      let data = null;
      let lineTension = interpolateChecked ? 0.0 : 0.2;

      switch (forecastVariation) {
        case "lWave":
          yaxis = "Rad. Flux (W/m²)";
          data = solarRadiation[format]["lWave"];
          highResData = highResSolar?.solarL;
          break;
        case "sWave":
          data = solarRadiation[format]["sWave"];
          yaxis = "Rad. Flux (W/m²)";
          highResData = highResSolar?.solarS;
          break;
        case "Power":
          data = solarPowerData[format]["Power"];
          yaxis = "MW";
          highResData = solarPowerData.ohhfc;
          break;
      }

      return (
        <ScatterGraph
          timeZone={timeZone}
          data={data.percentiles}
          highResData={highResData}
          control={data.control}
          average={data.average}
          yaxis={yaxis}
          lineTension={lineTension}
        />
      );
    }

    if (activeForecast.includes("processed-")) {
      const [_, processedActiveForecast] = activeForecast.split("-");
      const processedModelRaw =
        processedModelData?.[processedActiveForecast]?.models[
          forecastVariation
        ];
      const processedDataRaw = processedModelRaw?.data;
      if (!processedDataRaw) {
        return renderLoadingIcon();
      }
      // Craig remove channels la
      const { p10, p25, p50, p75, p90, mean, ...otherChannels } =
        formatProcessedDataGraph(processedDataRaw);

      return (
        <ProcessedScatterGraph
          yaxis={processedModelRaw?.yUnit}
          data={{ p10, p25, p50, p75, p90 }}
          otherChannels={otherChannels}
          average={mean}
          timeZone={timeZone}
        />
      );
    }
  };

  // Move to Util Craig
  const formatProcessedDataGraph = (rawData) => {
    const formattedData = {};

    // Iterate through each datetime key in the input object
    for (const dateTime in rawData) {
      const dataPoint = rawData[dateTime];

      // Iterate through each percentile property in the nested object
      for (const percentile in dataPoint) {
        let percentileLabel = percentile;
        if (!isNaN(percentile)) {
          percentileLabel = `p${parseInt(parseFloat(percentile) * 100)}`;
        }
        const value = dataPoint[percentile];

        // If the percentile property doesn't exist in the formattedData, create it as an array
        if (!formattedData[percentileLabel]) {
          formattedData[percentileLabel] = [];
        }

        formattedData[percentileLabel].push({
          x: new Date(dateTime),
          y: value,
        });
      }
    }

    return formattedData;
  };

  // move to a util
  const getFlatProcessedModelData = (
    forecast,
    fcVariation,
    separator = "-"
  ) => {
    const processedMDLData =
      processedModelData?.[forecast]?.models[fcVariation]?.data;
    if (!processedMDLData) {
      return null;
    }
    const flatprocessedData = [];
    return Object.keys(processedMDLData).map((dateKey) => {
      const dataPoint = processedMDLData[dateKey];
      const recordData = {};
      for (const percentile in dataPoint) {
        let percentileLabel = percentile;
        if (!isNaN(percentile)) {
          percentileLabel = `p${parseInt(parseFloat(percentile) * 100)}`;
        }
        recordData[percentileLabel] = dataPoint[percentile].toFixed(3);
      }
      recordData["dateTime"] = new Date(dateKey).toISOString();
      return recordData;
    });
  };

  // change to utils
  const getFlatData = (forecast, fcVariation, separator = "-") => {
    // if processed do something else
    if (forecast.includes("processed-")) {
      // Craig update
      const data = getFlatProcessedModelData(
        forecast.replace("processed-", ""),
        fcVariation,
        (separator = "-")
      );
      return data;
    }

    let flatData = null;
    let data = null;
    let highRes = null;
    let format = interpolateChecked ? "interpolated" : "raw";
    let variationEquivalent = fcVariation;
    switch (forecast) {
      case "Precipitation":
        data = precipitation;
        highRes = highResPrecip?.[fcVariation];
        break;
      case "Wind10":
        data = wind10;
        highRes = highResWind10?.[fcVariation];
        break;
      case "Wind80":
        if (fcVariation === "Power") {
          data = windPower80Data;
          highRes = highResWindPower80Data;
          break;
        }
        data = wind80;
        highRes = highResWind80?.[fcVariation];
        break;
      case "Solar":
        if (fcVariation === "Power") {
          data = solarPowerData;
          highRes = solarPowerData.ohhfc;
          break;
        } else {
          if (fcVariation === "sWave") {
            variationEquivalent = "sWave";
            highRes = highResSolar.solarS;
          }
          if (fcVariation === "lWave") {
            variationEquivalent = "lWave";
            highRes = highResSolar.solarL;
          }
          data = solarRadiation;
        }
        break;
    }

    if (!data) {
      return null;
    }

    flatData = {
      ...data[format][variationEquivalent],
      ...data[format][variationEquivalent].percentiles,
      hourly: highRes,
    };
    delete flatData.percentiles;

    const dataSetKeys = Object.keys(flatData);
    const tabularData = {};
    dataSetKeys.forEach((key, i) => {
      const isoLabelledData = convertToISOString(flatData[key]);
      isoLabelledData.forEach((record) => {
        if (!tabularData[record.x]) {
          tabularData[record.x] = {
            dateTime: record.x,
            ...createObjectWithPlaceholders(dataSetKeys, separator),
          };
        }
        tabularData[record.x][key] = record.y.toFixed(3);
      });
    });

    return Object.values(tabularData).sort((a, b) =>
      a.dateTime.localeCompare(b.dateTime)
    );
  };

  // Craig Utils later
  const convertToISOString = (arr) => {
    return arr.map((item) => {
      return {
        y: item.y,
        x: new Date(item.x).toISOString(),
      };
    });
  };
  //Craig Replace Later OR UTILS
  const createObjectWithPlaceholders = (properties, placeholder = "-") => {
    return properties.reduce((obj, prop) => {
      obj[prop] = placeholder;
      return obj;
    }, {});
  };

  const convertToCSV = (objArray, metadata) => {
    if (!objArray.length) return ""; // Return empty string if no data

    let csvContent = "";

    // Metadata
    Object.keys(metadata).forEach((item) => {
      let line = `${item}: ${metadata[item]}`;
      csvContent += line + "\r\n";
    });
    // add blank space
    csvContent += "\r\n";

    // Extract headers
    const headers = Object.keys(objArray[0]);
    // Move 'dateTime' to the first position
    const reorderedHeaders = [
      "dateTime",
      ...headers.filter((header) => header !== "dateTime"),
    ];
    const reNamedHeader = [
      "Datetime",
      ...headers.filter((header) => header !== "dateTime"),
    ];
    csvContent += reNamedHeader.join(",") + "\r\n";

    // Extract rows
    objArray.forEach((item) => {
      let line = reorderedHeaders.map((field) => item[field]).join(",");
      csvContent += line + "\r\n";
    });

    return csvContent;
  };
  const handleCSVDownload = () => {
    let forecastIssuedDate;
    if (activeForecast.includes("processed-")) {
      forecastIssuedDate =
        processedModelData?.[activeForecast.split("-")[1]]?.forecast_issued;
    } else {
      if (!dateTimeCycle) {
        //solves race condition if not loaded
        return;
      }
      const [year, month, day, hour] = dateTimeCycle.split("-");
      forecastIssuedDate = new Date(
        Date.UTC(year, month - 1, day, hour)
      ).toISOString();
    }
    const metadata = {
      Name: selectedAsset.name,
      Description: selectedAsset.description,
      Location: `${selectedAsset.lat}, ${selectedAsset.lng}`,
      "Data Type": `${activeForecast} ${forecastVariation}`,
      "Forecast Issued": forecastIssuedDate,
      ...(activeForecast === "Wind80" &&
        forecastVariation === "Power" && {
          "Wind Energy Model": selectedAsset?.windCurve?.name,
        }),
      ...(activeForecast === "Solar" &&
        forecastVariation === "Power" && {
          "Solar Energy Model": selectedAsset?.solarCurve?.name,
        }),
    };

    const csvData = getFlatData(activeForecast, forecastVariation, "");

    // selectedAsset.name
    const interpolatedSuffix = interpolateChecked ? "_interpolated" : "";
    const csvStr = convertToCSV(csvData, metadata);
    const blob = new Blob([csvStr], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `${selectedAsset?.name}-${activeForecast}-${forecastVariation}-${dateTimeCycle}${interpolatedSuffix}`
        ?.trim()
        .replace(".", "")
        .replace(/ /g, "-")
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    record({
      name: "downloadCSV",
      attributes: {
        variation: forecastVariation,
        forecast: activeForecast,
      },
    });

    ReactGA.event({
      category: "Data",
      action: "Download CSV",
      label: `${activeForecast}_${forecastVariation}`,
    });
  };

  const foreCastSchema = useMemo(() => {
    const tempForecastSchema = {
      Precipitation: {
        name: "Precipitation",
        Icon: CloudRaining,
        variations: [
          {
            name: "Hourly",
            key: "Hourly",
          },
          {
            name: "Cumulative",
            key: "Cumulative",
          },
        ],
      },
      Wind80: {
        name: "Wind (80m)",
        Icon: WindIcon,
        variations: [
          ...(windPower80Data ? [{ name: "Power", key: "Power" }] : []),
          {
            name: "Speed",
            key: "Speed",
          },
          {
            name: "Direction",
            key: "Direction",
          },
        ],
      },
      Wind10: {
        name: "Wind (10m)",
        Icon: WindIcon,
        variations: [
          {
            name: "Speed",
            key: "Speed",
          },
          {
            name: "Direction",
            key: "Direction",
          },
        ],
      },
      Solar: {
        name: "Solar",
        Icon: SunIcon,
        variations: [
          ...(solarPowerData ? [{ name: "Power", key: "Power" }] : []),
          {
            name: "Short Wave",
            key: "sWave",
          },
          {
            name: "Long Wave",
            key: "lWave",
          },
        ],
      },
    };

    if (processedModelData && selectedAsset?.processedModels?.items) {
      selectedAsset?.processedModels?.items?.forEach((element) => {
        const variations = processedModelData?.[element?.routeShort]?.models; //need this because if you switch fast, there might be a race condition. Maybe add short name to processedModel?
        if (variations) {
          const variationKeys = Object.keys(variations).map((key) => {
            return {
              key,
              name: variations[key].variation,
            };
          });

          tempForecastSchema[`processed-${element.routeShort}`] = {
            name: element.label,
            Icon: HurricaneIcon, //Craig Change
            variations: variationKeys,
          };
        }
      });
    }

    return tempForecastSchema;
  }, [windPower80Data, solarPowerData, selectedAsset, processedModelData]);

  const renderDashboardView = () => {
    if (displayView === "hybrid") {
      return (
        <div className="flex-1">
          <div className=" ">
            <div className="p-2 h-[40vh] ">{renderGraph()}</div>
            <div>
              <MapViewerReact
                defaultLat={selectedAsset.lat}
                defaultLng={selectedAsset.lng}
                OnClick={() => console.log("well done")}
              />
            </div>
          </div>
        </div>
      );
    } else if (displayView === "graph") {
      return <div className="flex-1 py-2 px-4">{renderGraph()}</div>;
    } else if (displayView === "table") {
      return <div>{renderTable()}</div>;
    }
  };

  return (
    <div className="flex-1 flex-col flex bg-white  border-x-1 border-eai-secondary-blue">
      <div className="p-5 flex justify-between ">
        <div className="flex cursor-pointer  rounded-md  items-center  ">
          <TabViewSelect
            viewSchema={viewSchema}
            activeView={displayView}
            onViewSelect={(view) => setDisplayView(view)}
          />
          <ForecastSelect
            activeForecast={activeForecast}
            foreCastSchema={foreCastSchema}
            onForecastSelect={(foreCastName) => {
              setForecastandVariation(foreCastName);
            }}
          />
          <div>
            <label className="text-xs mx-3 p-1 font-Inter">
              <input
                className="accent-eai-primary-accent"
                type="checkbox"
                checked={interpolateChecked}
                onChange={(e) => {
                  record({
                    name: "toggleInterpolateForecast",
                    attributes: {
                      interpolateOn: `${e.target.checked}`,
                    },
                  });
                  setInterpolateChecked(e.target.checked);
                }}
              />
              {"   "}Interpolate
            </label>
          </div>
        </div>
        <div className="flex grow">
          {/* <div className="flex">  CRAIG SWITCH OUT FOR TABULART MODE*/}
          <div className="hidden">
            {/* <div className="ml-4 p-2">View:</div> */}
            <select
              className="border rounded w-24 ml-1 pl-2"
              value={displayView}
              onChange={(e) => setDisplayView(e.target.value)}
            >
              <option key="hybrid" value="hybrid">
                Hybrid
              </option>
              <option key="graph" value="graph">
                Graph{" "}
              </option>
              <option key="table" value="table">
                Table{" "}
              </option>
            </select>
          </div>
        </div>
        <div className="flex cursor-pointer bg-gray-100 border border-gray-200 rounded-md overflow-hidden items-center px-1">
          {foreCastSchema[activeForecast] &&
            foreCastSchema[activeForecast].variations.map((variation) => {
              return (
                <div
                  key={variation.key}
                  onClick={() => handleVariationChange(variation.key)}
                  className={`flex flex-row ${
                    forecastVariation === variation.key
                      ? "bg-white font-semibold text-gray-900"
                      : "bg-grey-200 text-gray-400"
                  } items-center rounded-md`}
                >
                  <div
                    className=" text-xs mx-3 p-1 font-Inter"
                    title={variation.name}
                  >
                    {variation.name}
                  </div>
                </div>
              );
            })}
        </div>
        <button
          onClick={handleCSVDownload}
          title="download csv"
          className="ml-2 flex cursor-pointer bg-gray-100 border border-gray-200 rounded-md overflow-hidden items-center px-1"
        >
          <DownloadIcon />
        </button>
      </div>
      {renderDashboardView()}
    </div>
  );
};

export default AssetGraph;
