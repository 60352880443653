// @ts-nocheck
import React from "react";
import AssetHeaderGroup from "./AssetHeaderGroup";
import AssetLatLon from "./AssetLatLon";
import { useDispatch } from "react-redux";
import { modalViewsConst, setModalState } from "../store/modalSlice";
import { schemaMatch } from "../util/utils";
import {
  assetSchema,
  createProcessedModel,
  postProcessedModel,
} from "../store/assetsSlice";
import { uploadData } from "@aws-amplify/storage";
import { processedSample } from "../assets/json/processedSample";

const AssetDescription = ({ asset, assetOwner }) => {
  const dispatch = useDispatch();

  const handleEditAsset = () => {
    dispatch(
      setModalState({
        modalOpen: true,
        modalView: modalViewsConst.editAssetNEW,
        properties: schemaMatch(asset, assetSchema),
      })
    );
  };

  const handleOpenProcessedModels = () => {
    dispatch(
      setModalState({
        modalOpen: true,
        modalView: modalViewsConst.processedModelDownload,
        properties: { asset: schemaMatch(asset, assetSchema), assetOwner },
      })
    );
  };

  const createBlankProcessedModel = async () => {
    const processedmodelShort = "turbineflows";
    const newProcessedModel = {
      assetId: asset.id,
      label: "Turbine Flow",
      routeShort: processedmodelShort,
      routeLong: `private/${assetOwner.cognitoIdentityId}/processedModels/${asset.id}/${processedmodelShort}/`,
      displayType: "scatter",
      owner: assetOwner.id,
    };
    console.log("newProcessedModel", newProcessedModel);
    dispatch(postProcessedModel(newProcessedModel));
    try {
      const jsonData = JSON.stringify(processedSample);
      const result = await uploadData({
        path: `private/${assetOwner.cognitoIdentityId}/processedModels/${asset.id}/${processedmodelShort}/2024-08-20-06-placeholder.json`, // Specify the S3 path where the JSON will be uploaded
        data: new Blob([jsonData], { type: "application/json" }), // Convert JSON string to Blob
        options: {
          contentType: "application/json", // Ensure correct MIME type
          onProgress: (progress) => {
            console.log(`Progress: ${progress.loaded}/${progress.total}`);
          },
        },
      }).result;

      console.log("Upload succeeded:", result);
    } catch (error) {
      console.error("Error uploading JSON to S3:", error);
    }
  };

  return (
    <div className="border-left border-y bg-white border-grey-200 p-4 pt-6">
      <div className="flex justify-between mb-4">
        <AssetHeaderGroup
          asset={asset}
          // assetDesc={asset.description}
          // assetName={asset.name}
        />
        <div>
          {/* <div
          onClick={handleEditAsset}
          className="text-sm text-green-600  hover:underline  text-right h-8 mb-auto cursor-pointer"
          >
          Manage Asset
        </div> */}
          <div>
            <button
              className="eai-btn hidden"
              onClick={createBlankProcessedModel}
            >
              Create Blank processed Model
            </button>
            {asset?.processedModels?.items?.length > 0 && (
              <button
                className="eai-btn-grn"
                onClick={handleOpenProcessedModels}
              >
                Model Results
              </button>
            )}
          </div>
        </div>
      </div>
      <AssetLatLon lat={asset.lat} lng={asset.lng} value={asset.value} />
    </div>
  );
};

export default AssetDescription;
